<template>
  <v-container fluid grid-list-lg class="pa-0">
    <SearchHeader :onAction="doSearch"></SearchHeader>

    <v-data-table :headers="headers" :items="banks" :items-per-page="100" :search="search" class="elevation-1 ma-5">
      <template v-slot:item.action="{ item }">
        <v-icon @click="editItem(item)">
          mdi-account-edit
        </v-icon>
        <v-icon @click="deleteItem(item)">
          mdi-account-remove
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("common.list.noResults") }}
      </template>
    </v-data-table>

    <CfgPanel ref="cfgpanel" :onSave="save" :title="$t('banks.edit.title')" :tabIndex="1001">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="bank.name"
                :rules="rules.required"
                :label="$t('banks.list.name')"
                required
                ref="firstfield"
                :tabIndex="1000"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("banks.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </CfgPanel>
  </v-container>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin } from "@/helpers/mixins";

import CfgPanel from "@/components/_common/cfgpanel";
import SearchHeader from "@/components/_common/searchheader";

export default {
  mixins: [formatMixin, listMixin],

  components: {
    CfgPanel,
    SearchHeader,
  },

  data() {
    return {
      valid: false,
      show1: false,
      search: "",
      headers: [
        { text: this.$i18n.t("banks.list.name"), align: "left", value: "name" },
        { text: this.$i18n.t("common.list.actions"), value: "action", sortable: false },
      ],
      banks: [],
      editedIndex: -1,
      bank: {},
      rules: {
        required: [(v) => !!v || this.$i18n.t("common.form.required")],
        email: [(v) => !!v || this.$i18n.t("common.form.requiredEmail"), (v) => /.+@.+\..+/.test(v) || this.$i18n.t("common.form.validEmail")],
        pwdvalidate: [(v) => !!v || this.$i18n.t("common.form.requiredEmail"), (v) => v == this.user.password || this.$i18n.t("common.form.identicalEmail")],
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New bank" : "Edit bank";
    },
  },

  created() {
    this.loadbanks();
  },

  methods: {
    doSearch: function(val) {
      this.search = val
    },

    loadbanks: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/banks`)
        .then((response) => {
          this.banks = response.data;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    reset: function() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    editItem: function(item) {
      this.$refs.cfgpanel.show();
      this.$refs.firstfield.focus();
      var u = null;
      this.editedIndex = -1;
      this.bank = {};

      if (item) {
        u = this.banks.find((bank) => {
          return bank.id === item.id;
        });
        this.editedIndex = this.banks.indexOf(u);
        if (this.editedIndex >= 0) {
          this.bank = Object.assign({}, u);
        }
      }
    },

    deleteItem: function(item) {
      if (confirm("Are you sure you want to delete this bank?")) {
        axios
          .delete(`/api/banks/${item.id}`)
          .then(() => {
            this.loadbanks();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    save: function() {
      if (this.$refs.form.validate()) {
        let u = { ...this.bank };
        delete u.createdAt;
        delete u.updatedAt;

        axios
          .post(`/api/banks/${u.id || ""}`, u)
          .then((msg) => {
            this.$store.dispatch("showSnackMessage", { message: "Data saved." });
            this.$refs.cfgpanel.hide();
            this.updateItemList(this.banks, msg.data);
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
  },
};
</script>
